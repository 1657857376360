import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const SwaggerPage = () => {
  return (
    <div>
      <h1>Swagger UI</h1>
      <SwaggerUI url="https://ngu.azurewebsites.net/docs" />
    </div>
  );
};

export default SwaggerPage;
