import React, { useState } from 'react';
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const AppBar = ({ toggleTheme, themeMode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const appBarStyle = {
    backgroundColor: 'transparent',
    color: themeMode === 'dark' ? '#000' : '#fff',
    boxShadow: 'none',
  };

  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/tokens">
          <ListItemText primary="Token Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard">
          <ListItemText primary="Token Richlist" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <MuiAppBar position="static" style={appBarStyle}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
            <Typography variant="subtitle1" style={{ color: textColor }}>
              Number <span style={{ color: '#00ff00' }}>Go</span> Up
            </Typography>
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};

export default AppBar;
