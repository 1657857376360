import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AppBar from './components/appbar';
import FrontendComponent from './components/tokens';
import Dashboard from './components/dashboard';
import SwaggerPage from './components/swagger';


const App = () => {
  const [themeMode, setThemeMode] = useState('dark');

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = createTheme({
    palette: {
      mode: themeMode,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={`app ${themeMode}`}>
        <Router>
          <AppBar toggleTheme={toggleTheme} themeMode={themeMode} />
          <Routes>
            <Route path="/tokens" element={<FrontendComponent />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/swagger" element={<SwaggerPage />} /> {/* Route for Swagger documentation */}
            <Route path="/" element={<Navigate to="/tokens" />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
