import axios from 'axios';

// Define the Azure Function endpoint URL
const azureFunctionUrl = 'https://ngu.azurewebsites.net/api/pullPairData';

const fetchData = async (selectedToken) => {
  try {
    const response = await axios.get(azureFunctionUrl, {
      params: {
        symbol: selectedToken
      }
    });
    // Handle successful response
    console.log('Pair data:', response.data);

    // Filter pairs where token0_name is "Alephium"
    const alephiumPairs = response.data.filter(pair => pair.token0_name === "Alephium");

    // Find the pair with the latest timestamp
    let latestPair = null;
    let latestTimestamp = 0;
    alephiumPairs.forEach(pair => {
      const timestamp = new Date(pair.timestamp).getTime();
      if (timestamp > latestTimestamp) {
        latestTimestamp = timestamp;
        latestPair = pair;
      }
    });

    return latestPair;
  } catch (error) {
    // Handle error
    console.error('Error fetching pair data:', error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export default fetchData;
