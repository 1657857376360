import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import TokenAlert from './tokenalert'; // Import TokenAlert component
import axios from 'axios'; // Import axios for testing

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212', // Dark background color
      paper: '#1E1E1E', // Dark paper color
    },
    text: {
      primary: '#FFFFFF', // White text color
      secondary: '#FFD700', // Yellow secondary color for important metrics
    },
  },
  typography: {
    fontSize: 12,
  },
});

const formatAddress = (address) => {
  const lowerCaseAddress = address.toLowerCase();
  return `${lowerCaseAddress.substring(0, 4)}...${lowerCaseAddress.substring(lowerCaseAddress.length - 4)}`;
};

const formatBalance = (balance) => {
  return Math.round(balance).toLocaleString();
};

const Dashboard = () => {
  const [selectedToken, setSelectedToken] = useState('');
  const [tokenList, setTokenList] = useState([]);
  const [tokenHolders, setTokenHolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalHolders, setTotalHolders] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTokenName, setSelectedTokenName] = useState('');
  const [lastChange, setLastChange] = useState(null);

  useEffect(() => {
    const fetchTokenList = async () => {
      try {
        const response = await fetch('https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json');
        if (!response.ok) {
          throw new Error('Failed to fetch token list');
        }
        const data = await response.json();
        console.log('Token List:', data.tokens);
        setTokenList(data.tokens);
        setSelectedToken('NGU');
      } catch (error) {
        console.error('Error fetching token list:', error);
        setError('Error fetching token list. Please try again later.');
      }
    };

    fetchTokenList();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedToken) return;
      console.log('Fetching data for token:', selectedToken);
      try {
        const response = await fetch(`https://ngu.azurewebsites.net/api/pull_db?symbol=${selectedToken}`);

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        console.log('Fetched data:', data);

        // Check if data is an array
        if (!Array.isArray(data)) {
          throw new Error('Data is not in the expected format.');
        }

        // Sort data if it's an array
        data.sort((a, b) => b.Balance - a.Balance);

        setTokenHolders(data);
        setTotalHolders(data.length);
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError({ code: error.code, message: 'Error fetching data. Please try again later.' });
        setLoading(false);
      }
    };

    setLoading(true);
    fetchData();
  }, [selectedToken]);

  const handleTokenChange = (event) => {
    const newToken = event.target.value;
    console.log('Selected token:', newToken);
    console.log('Available tokens:', tokenList.map(token => token.symbol)); // Log available tokens
    const selectedTokenData = tokenList.find(token => token.symbol === newToken);
    setSelectedToken(newToken); // Update selected token
    setSelectedTokenName(selectedTokenData?.name || ''); // Update selected token name
    console.log('Selected token name:', selectedTokenData?.name || ''); // Add this line for debugging
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTokenHolders = tokenHolders.filter(holder =>
    holder.Address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to handle last change received from TokenAlert component
  const handleLastChange = (change) => {
    setLastChange(change);
  };

  // Function to test TokenAlert component
  const testTokenAlert = async () => {
    try {
      // Simulate a request to the GitHub API (replace with your actual test logic)
      const response = await axios.get('https://api.github.com/repos/cyxeIO/token-list/commits');
      console.log('Test result:', response.data);
    } catch (error) {
      console.error('Error testing TokenAlert:', error);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          padding: '20px',
        }}
      >
        <Typography variant="h6" sx={{ color: '#FFFFFF', marginBottom: '20px' }}>
          {selectedTokenName}
        </Typography>
        <Box sx={{ width: '100%', marginBottom: '20px', textAlign: 'center' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel
              id="token-select-label"
              sx={{ color: '#fff', textAlign: 'center' }}
            >
            </InputLabel>
            <Select
              labelId="token-select-label"
              id="token-select"
              value={selectedToken}
              onChange={handleTokenChange}
              sx={{ color: '#fff', minWidth: 200 }}
            >
              {tokenList.map(token => (
                <MenuItem key={token.symbol} value={token.symbol}>
                  <img src={token.logoURI} alt={`${token.name} Logo`} style={{ height: 20, marginRight: 5 }} />
                  {token.symbol}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200, margin: 'auto' }}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Address"
                inputProps={{ 'aria-label': 'search by address' }}
                value={searchQuery}
                onChange={handleSearch}
              />
              <SearchIcon sx={{ mr: 1 }} />
            </Paper>
          </Box>
        </Box>

        <Grid container spacing={2} justifyContent="center"> {/* Add justifyContent="center" */}
          <Grid item auto={6}>
            <Paper sx={{ padding: '10px', backgroundColor: 'transparent', marginBottom: '10px', textAlign: 'center' }}>
              <Typography variant="h6" sx={{ color: '#fff', marginBottom: '10px' }}>
                Token Holders ({totalHolders})
              </Typography>
              <TableContainer sx={{ maxHeight: '50vh', overflow: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Rank</TableCell>
                      <TableCell align="center">Address</TableCell>
                      <TableCell align="center">Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : error ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center" sx={{ color: 'error.main' }}>
                          {error.message}
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredTokenHolders.map((holder, index) => (
                        <TableRow key={holder.Address}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            <Tooltip title={holder.Address}>
                              <a href={`https://explorer.alephium.org/addresses/${holder.Address}`} target="_blank" rel="noopener noreferrer">
                                <Typography sx={{ maxWidth: '120px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#fff' }}>
                                  {formatAddress(holder.Address)}
                                </Typography>
                              </a>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center" sx={{ color: '#00FF00' }}>{formatBalance(holder.Balance)}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>

        {/* Button to test TokenAlert component */}
   

      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;