import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app'; // Make sure the import path is correct
//import MarketDashboard from './components/chaindata'; // Import your MarketDashboard component


// Find the root element in your HTML
const rootElement = document.getElementById('root');
// Create a root.
const root = createRoot(rootElement);

// Initial render: Render an element to the root.
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
