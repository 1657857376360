import React, { useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, Typography, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper, TableSortLabel } from '@mui/material';
import fetchData from './fetchpairs';
import './dashboard.css';
import debounce from 'lodash.debounce';

const FrontendComponent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tokenList, setTokenList] = useState([]);
  const [pairData, setPairData] = useState({});
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');

  const debouncedHandleUpdateStats = useCallback(
    debounce(async (token) => {
      try {
        setLoading(true);
        const data = await fetchData(token);
        setPairData(prevState => ({
          ...prevState,
          [token]: data
        }));
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }, 1000),
    []
  );

  const fetchTokenData = async (tokenSymbol) => {
    try {
      setLoading(true);
      const data = await fetchData(tokenSymbol);
      setPairData(prevState => ({
        ...prevState,
        [tokenSymbol]: data
      }));
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const fetchTokenList = async () => {
    try {
      const response = await fetch('https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json');
      if (!response.ok) {
        throw new Error('Failed to fetch token list');
      }
      const data = await response.json();
      setTokenList(data.tokens);
  
      // Fetch data for each token
      for (const token of data.tokens) {
        await fetchTokenData(token.symbol);
      }
    } catch (error) {
      console.error('Error fetching token list:', error);
      setError('Error fetching token list. Please try again later.');
    }
  };

  useEffect(() => {
    fetchTokenList();
  }, []); // Empty dependency array to run only once when component mounts

  const fetchAndUpdatePairData = async () => {
    try {
      setLoading(true);
      for (const token of tokenList) {
        await debouncedHandleUpdateStats(token.symbol);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const formatNumber = (number) => {
    return parseFloat(number).toFixed(2);
  };

  const getPriceBox = (value) => {
    const numericValue = parseFloat(value);
    const formattedValue = numericValue.toFixed(6); // Format to six decimal places
    const color = numericValue > 0 ? 'green' : numericValue < 0 ? 'red' : '#008000'; // Stock market green for 0 value
  
    return (
      <Box
        className="data-box"
        style={{
          background: 'black',
          color: 'white',
          padding: '10px',
          borderRadius: '10px',
          cursor: 'pointer',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          transition: 'box-shadow 0.3s, transform 0.3s',
          '&:hover': {
            boxShadow: '0 0 20px rgba(0, 255, 0, 0.7)',
            transform: 'scale(1.05)',
          },
        }}
      >
        {'$' + formattedValue} {/* Displaying price with six decimal places */}
      </Box>
    );
  };
  

  
  const getPriceChangeBox = (value) => {
    const numericValue = parseFloat(value);
    const formattedValue = numericValue.toFixed(2); // Format to two decimal places
    let color;
  
    if (numericValue > 0) {
      color = '#00ff00'; // Green for positive values
    } else if (numericValue < 0) {
      color = 'red'; // Red for negative values
    } else {
      color = '#008000'; // Stock market green for 0 value
    }
  
    const arrow = numericValue > 0 ? '\u25b2' : numericValue < 0 ? '\u25bc' : ''; // Unicode arrow character
  
    return (
      <Box
        className="data-box"
        style={{
          background: 'black',
          color: color,
          padding: '10px',
          borderRadius: '10px',
          cursor: 'pointer',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          transition: 'box-shadow 0.3s, transform 0.3s',
          '&:hover': {
            boxShadow: '0 0 20px rgba(0, 255, 0, 0.7)',
            transform: 'scale(1.05)',
          },
          display: 'flex',
          alignItems: 'center', // Center arrow and text vertically
        }}
      >
        <span style={{ marginRight: '5px' }}>{arrow}</span> {/* Display arrow before the formatted number */}
        {formattedValue}% {/* Display formatted number */}
      </Box>
    );
  };

  
  const getSymbolBox = (symbol) => {
    const numericValue = parseFloat(symbol);
    const formattedValue = numericValue.toFixed(6); // Format to six decimal places
    const color = numericValue > 0 ? 'green' : numericValue < 0 ? 'red' : '#008000'; // Stock market green for 0 value
  
    return (
      <Box
        className="data-box"
        style={{
          background: 'black',
          color: 'white',
          padding: '10px',
          borderRadius: '10px',
          cursor: 'pointer',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          transition: 'box-shadow 0.3s, transform 0.3s',
          display: 'flex', // Added to align items vertically
          alignItems: 'center', // Added to center items vertically
          '&:hover': {
            boxShadow: '0 0 20px rgba(0, 255, 0, 0.7)',
            transform: 'scale(1.05)',
          },
        }}
      >
        <img src={symbol.logoURI} alt={symbol.symbol} style={{ width: 20, marginRight: '5px' }} /> {/* Added margin for spacing */}
        <span>{symbol.symbol}</span>
      </Box>
    );
  };


  const getSupplyBox = (value) => {
    return (
      <Box
        className="data-box"
        style={{
          background: 'black',
          color: 'white',
          padding: '10px',
          borderRadius: '10px',
          cursor: 'pointer',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          transition: 'box-shadow 0.3s, transform 0.3s',
          '&:hover': {
            boxShadow: '0 0 20px rgba(0, 255, 0, 0.7)',
            transform: 'scale(1.05)',
          },
        }}
      >
        {Math.round(value).toLocaleString()}
      </Box>
    );
  };

  const getUSDBox = (value) => {
    return (
      <Box
        className="data-box"
        style={{
          background: 'black',
          color: 'white',
          padding: '10px',
          borderRadius: '10px',
          cursor: 'pointer',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          transition: 'box-shadow 0.3s, transform 0.3s',
          '&:hover': {
            boxShadow: '0 0 20px rgba(0, 255, 0, 0.7)',
            transform: 'scale(1.05)',
          },
        }}
      >
        {'$' + parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
      </Box>
    );
  };

  const calculateMarketCap = (price, totalSupply) => {
    return price * totalSupply;
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    switch (orderBy) {
      case 'name':
        return order === 'desc'
          ? (a, b) => descendingComparator(a.symbol, b.symbol)
          : (a, b) => -descendingComparator(a.symbol, b.symbol);
      case 'token1_price':
      case 'price_change_5min':
      case 'price_change_1h':
      case 'price_change_4h':
      case 'price_change_24h':
        return order === 'desc'
          ? (a, b) => descendingComparator(pairData[a.symbol]?.[orderBy], pairData[b.symbol]?.[orderBy])
          : (a, b) => -descendingComparator(pairData[a.symbol]?.[orderBy], pairData[b.symbol]?.[orderBy]);
        case 'volume24h':
  return order === 'desc'
    ? (a, b) => descendingComparator(pairData[a.symbol]?.volume24h, pairData[b.symbol]?.volume24h)
    : (a, b) => -descendingComparator(pairData[a.symbol]?.volume24h, pairData[b.symbol]?.volume24h);
    case 'marketCap':
      return order === 'desc'
        ? (a, b) => descendingComparator(calculateMarketCap(pairData[a.symbol]?.token1_price, pairData[a.symbol]?.token1_totalSupply), calculateMarketCap(pairData[b.symbol]?.token1_price, pairData[b.symbol]?.token1_totalSupply))
        : (a, b) => -descendingComparator(calculateMarketCap(pairData[a.symbol]?.token1_price, pairData[a.symbol]?.token1_totalSupply), calculateMarketCap(pairData[b.symbol]?.token1_price, pairData[b.symbol]?.token1_totalSupply));
    
    case 'token1_totalSupply':
      return order === 'desc'
        ? (a, b) => descendingComparator(pairData[a.symbol]?.token1_totalSupply, pairData[b.symbol]?.token1_totalSupply)
        : (a, b) => -descendingComparator(pairData[a.symbol]?.token1_totalSupply, pairData[b.symbol]?.token1_totalSupply);
    
    case 'token1_circulatingSupply':
      return order === 'desc'
        ? (a, b) => descendingComparator(pairData[a.symbol]?.token1_circulatingSupply, pairData[b.symbol]?.token1_circulatingSupply)
        : (a, b) => -descendingComparator(pairData[a.symbol]?.token1_circulatingSupply, pairData[b.symbol]?.token1_circulatingSupply);
    
      case 'liquidity':
        return order === 'desc'
          ? (a, b) => descendingComparator(pairData[a.symbol]?.[orderBy] || 0, pairData[b.symbol]?.[orderBy] || 0)
          : (a, b) => -descendingComparator(pairData[a.symbol]?.[orderBy] || 0, pairData[b.symbol]?.[orderBy] || 0);
      default:
        return order === 'desc'
          ? (a, b) => descendingComparator(a.symbol, b.symbol)
          : (a, b) => -descendingComparator(a.symbol, b.symbol);
    }
  };

  const descendingComparator = (a, b) => {
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  };

  const sortedTokens = stableSort(tokenList, getComparator(order, orderBy));

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', background: 'black' }}>
    <Typography variant="h3" align="center" style={{ color: 'white' }}>

    </Typography>

    {loading && (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <CircularProgress />
      </Box>
    )}
 <TableContainer component={Paper} sx={{ marginTop: '10px', background: 'black' }}>
      
      <Table style={{ fontSize: '12px' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'name'}
                direction={orderBy === 'name' ? order : 'asc'}
                onClick={() => handleSortRequest('name')}
                style={{ color: 'white' }}
              >
                Token
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'token1_price'}
                direction={orderBy === 'token1_price' ? order : 'asc'}
                onClick={() => handleSortRequest('token1_price')}
                style={{ color: 'white' }}
              >
                Price
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'price_change_5min'}
                direction={orderBy === 'price_change_5min' ? order : 'asc'}
                onClick={() => handleSortRequest('price_change_5min')}
                style={{ color: 'white' }}
              >
               5min
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'price_change_1h'}
                direction={orderBy === 'price_change_1h' ? order : 'asc'}
                onClick={() => handleSortRequest('price_change_1h')}
                style={{ color: 'white' }}
              >
                1h
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'price_change_4h'}
                direction={orderBy === 'price_change_4h' ? order : 'asc'}
                onClick={() => handleSortRequest('price_change_4h')}
                style={{ color: 'white' }}
              >
                4h
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'price_change_24h'}
                direction={orderBy === 'price_change_24h' ? order : 'asc'}
                onClick={() => handleSortRequest('price_change_24h')}
                style={{ color: 'white' }}
              >
                24h
              </TableSortLabel>
            </TableCell>
            
            <TableCell>
              <TableSortLabel
                active={orderBy === 'volume24h'}
                direction={orderBy === 'volume24h' ? order : 'asc'}
                onClick={() => handleSortRequest('volume24h')}
                style={{ color: 'white' }}
              >
                Volume24h
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={orderBy === 'liquidity'}
                direction={orderBy === 'liquidity' ? order : 'asc'}
                onClick={() => handleSortRequest('liquidity')}
                style={{ color: 'white' }}
              >
                Liquidity
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={orderBy === 'marketCap'}
                direction={orderBy === 'marketCap' ? order : 'asc'}
                onClick={() => handleSortRequest('marketCap')}
                style={{ color: 'white' }}
              >
                Market Cap
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                active={orderBy === 'token1_totalSupply'}
                direction={orderBy === 'token1_totalSupply' ? order : 'asc'}
                onClick={() => handleSortRequest('token1_totalSupply')}
                style={{ color: 'white' }}
              >
                Total Supply
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'token1_circulatingSupply'}
                direction={orderBy === 'token1_circulatingSupply' ? order : 'asc'}
                onClick={() => handleSortRequest('token1_circulatingSupply')}
                style={{ color: 'white' }}
              >
                Circulating Supply
              </TableSortLabel>
            </TableCell>

       

            
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTokens.map(token => (
            pairData[token.symbol] && (
              <TableRow key={token.symbol}>
                <TableCell style={{ color: 'white', fontSize: '12px' }}>{getSymbolBox(token)}</TableCell>
                <TableCell style={{ color: 'white', fontSize: '14px' }}>{getPriceBox(pairData[token.symbol]?.token1_price)}</TableCell>
                <TableCell style={{ color: 'white', fontSize: '14px' }}>{getPriceChangeBox(pairData[token.symbol]?.price_change_5min)}</TableCell>
                <TableCell style={{ color: 'white', fontSize: '14px' }}>{getPriceChangeBox(pairData[token.symbol]?.price_change_1h)}</TableCell>
                <TableCell style={{ color: 'white', fontSize: '14px' }}>{getPriceChangeBox(pairData[token.symbol]?.price_change_4h)}</TableCell>
                <TableCell style={{ color: 'white', fontSize: '14px' }}>{getPriceChangeBox(pairData[token.symbol]?.price_change_24h)}</TableCell>
                
                <TableCell style={{ color: 'white', fontSize: '12px' }}>{getUSDBox(pairData[token.symbol]?.volume24h)}</TableCell>
                <TableCell style={{ color: 'white', fontSize: '12px' }}>{getUSDBox(pairData[token.symbol]?.liquidity)}</TableCell>

                <TableCell style={{ color: 'white', fontSize: '12px' }}>
                  {getUSDBox(calculateMarketCap(pairData[token.symbol]?.token1_price, pairData[token.symbol]?.token1_totalSupply))}
                </TableCell>
                <TableCell style={{ color: 'white', fontSize: '12px' }}>{getSupplyBox(pairData[token.symbol]?.token1_totalSupply)}</TableCell>
                <TableCell style={{ color: 'white', fontSize: '12px' }}>{getSupplyBox(pairData[token.symbol]?.token1_circulatingSupply)}</TableCell>

              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);
};

export default FrontendComponent;
